<template>
    <b-row class="match-height">
      <b-col
lg="12"
> <b-card
      title="Purchase Order Close Confirmation By Lab Manager"
    >
      <b-form
          @submit.prevent="submitConfirm"
>
        <b-row>
          <b-col cols="12">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <b-button
              v-if="purchaseOrderByPurchasingIsConfirmed===true && purchaseOrderByWarehouseIsConfirmed==true"
              type="button"
              variant="primary"
              class="mr-1 float-rigth"
              @click="print()"
            >
              <feather-icon
                icon="PrinterIcon"
                size="16"
                class="align-middle"
              />
              Print
            </b-button>
            <div class="devider" />
            </b-col>
        <b-col cols="12">
          <b-form-group
            label="Supplier *"
            label-for="supplier"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_order_supplier"
              v-model="supplier"
              placeholder="Supplier"
              required="required"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Shipment By *"
            label-for="purchase_order_shipment_by"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_order_shipment_by"
              v-model="shipmentBy"
              placeholder="Shipment By"
              required="required"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Purchase Order Type *"
            label-for="purchase_order_type"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_order_type"
              v-model="type"
              placeholder="Purchase Order Type"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Delivery Address *"
            label-for="purchase_order_delivery_address"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_order_delivery_address"
              v-model="deliveryAddress"
              placeholder="Delivery Address"
              required="required"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Payment Type *"
            label-for="payment_type"
            label-cols-md="12"
          >
            <b-form-input
              id="payment_type"
              v-model="paymentType"
              placeholder="Payment Type"
              required="required"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Delivery Schedule *"
            label-for="purchase_order_delivery_schedule"
            label-cols-md="12"
              readonly
          >
            <b-form-input
              id="purchase_order_delivery_schedule"
              v-model="purchaseOrderSchedule"
              placeholder="Delivery Schedule"
              required="required"
              readonly
            />
          </b-form-group>
          <b-form-group
              label="Is With PPN "
              label-for="purchase_order_is_with_tax"
              label-cols-md="12"
              readonly
            >
              <b-form-input
                id="purchase_order_is_with_tax"
                v-model="purchaseOrderWithTax"
                readonly
              />
            </b-form-group>
          <b-form-group
            label="Billing Address *"
            label-for="billing_address"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_order_delivery_address"
              v-model="billingAddress"
              placeholder="Delivery Address"
              required="required"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Term Of Payment *"
            label-for="term_of_payment"
            label-cols-md="12"
          >
            <b-form-input
              id="term_of_payment"
              v-model="termOfPayment"
              placeholder="Term Of Payment"
              required="required"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Discount Percentage (%)"
            label-for="purchase_order_discount_percentage"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_order_discount_percentage"
              v-model="purchaseOrderDiscountPercentage"
              placeholder="Discount Percentage"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Link"
            label-for="purchase_order_link"
            label-cols-md="12"
          >
            <b-form-input
              id="purchase_order_link"
              v-model="purchaseOrderLink"
              placeholder="Link"
              readonly
            />
          </b-form-group>
        <b-form-group>
          <label for="no_invoice">Items :</label>
        </b-form-group>
        <b-form-group>
          <br>
           <div v-if="purchaseOrderByPurchasingIsConfirmed===false">
          <b-row>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Chosen Price
            </b-col>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Requested QTY
            </b-col>
            </b-row>
           <p />
          <b-row
v-for="(item) in items"
:key="item.purchase_order_detail_item_id"
>
          <br>
            <b-col
cols="4"
class="text-center"
>
                <b-list-group>
                  <b-list-group-item href="#">
{{ item.item.item_name }} ({{ item.item.item_code }})
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
cols="4"
class="text-center form-control"
>
              {{ item.item_reference!=null ? item.item_reference.currency.currency_symbol : item.item.currency.currency_symbol }}{{ numberFormat(item.purchase_order_detail_item_price) }}
            </b-col>
            <b-col
cols="4"
class="text-center"
>
            <b-form-input
              v-model="item.purchase_order_detail_item_qty"
              class="text-center"
              readonly
/>
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
            </div>
           <div v-if="purchaseOrderByPurchasingIsConfirmed===true && purchaseOrderByWarehouseIsConfirmed==false">
          <b-row>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Chosen Price
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Sent QTY
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Received
            </b-col>
            </b-row>
           <p />
          <b-row
v-for="(item) in items"
:key="item.purchase_order_detail_item_id"
>
          <br>
            <b-col
cols="4"
class="text-center"
>
                <b-list-group>
                  <b-list-group-item href="#">
{{ item.item.item_name }} ({{ item.item.item_code }})
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
cols="4"
class="text-center form-control"
>
            {{ item.item_reference!=null ? item.item_reference.currency.currency_symbol : item.item.currency.currency_symbol }} {{ numberFormat(item.purchase_order_detail_item_price) }}
            </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.purchase_order_detail_item_qty"
              class="text-center"
              readonly
/>
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
            </div>
           <div v-if="purchaseOrderByPurchasingIsConfirmed===true && purchaseOrderByWarehouseIsConfirmed==true">
          <b-row>
            <b-col
cols="6"
class="text-center font-weight-bold"
>
              Item name
            </b-col>
            <b-col
cols="4"
class="text-center font-weight-bold"
>
              Chosen Price
            </b-col>
            <b-col
cols="2"
class="text-center font-weight-bold"
>
              Sent QTY
            </b-col>
            </b-row>
           <p />
          <b-row
v-for="(item) in items"
:key="item.purchase_order_detail_item_id"
>
          <br>
            <b-col
cols="6"
class="text-center"
>
                <b-list-group>
                  <b-list-group-item href="#">
{{ item.item.item_name }} ({{ item.item.item_code }})
</b-list-group-item>
                </b-list-group>
              </b-col>
            <b-col
cols="4"
class="text-center form-control"
>
            {{ item.item_reference!=null ? item.item_reference.currency.currency_symbol : item.item.currency.currency_symbol }} {{ numberFormat(item.purchase_order_detail_item_price) }}
            </b-col>
            <b-col
cols="2"
class="text-center"
>
            <b-form-input
              v-model="item.purchase_order_detail_item_qty"
              class="text-center"
              readonly
/>
            </b-col>
            <b-col cols="12">
              <br>
            </b-col>
            </b-row>
            </div>
        </b-form-group>
        <b-form-group>
          <label for="no_invoice">Status :</label>
          <vue-horizontal-timeline :items="statusItems" />
        </b-form-group>
          </b-col>
          <b-col offset-md="12">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
            :disabled="submitButtonDisabled"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
        </b-form>
    </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { ref } from '@vue/composition-api'

  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const statusItems = []
  const supplier = ''
  const shipmenBy = ''
  const deliveryAddress = ''
  const purchaseOrderSchedule = ''
  const billingAddress = ''
  const termOfPayment = ''
  const paymentType = ''
  const type = ''
  const purchaseOrderByPurchasingIsConfirmed = false
  const purchaseOrderByWarehouseIsConfirmed = false
  const submitButtonDisabled = false
  const purchaseOrderLink = ''
  const purchaseOrderDiscountPercentage = ''
  const purchaseOrderWithTax = ''
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        purchaseOrderWithTax,
        type,
        purchaseOrderLink,
        purchaseOrderDiscountPercentage,
      purchaseOrderByPurchasingIsConfirmed,
      purchaseOrderByWarehouseIsConfirmed,
      paymentType,
      statusItems,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      supplier,
      shipmenBy,
      deliveryAddress,
      purchaseOrderSchedule,
      billingAddress,
      submitButtonDisabled,
      termOfPayment,
      }
    },
    mounted() {
        this.getDetail()
    },
    methods: {
        submitConfirm() {
          const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.submitButtonDisabled = true
  const item = JSON.parse(localStorage.getItem('podetail'))
        const id = item.purchase_order_id
            const body = {
            time: moment(),
            }
        axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_EARLY_FINISH_PURCHASE_ORDER}/${id}`, body, { headers })
        .then(async response => {
            if (response.data.success === true) {
      this.submitButtonDisabled = false
            this.$router.replace({ name: 'apps-procurement-purchase-order-list' })
            this.$toast({
                component: ToastificationContent,
                props: {
                title: 'Confirm Early Finish Purchase Order Success',
                icon: 'CheckIcon',
                variant: 'success',
                },
            })
            } else {
      this.submitButtonDisabled = false
            this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Something went wrong',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
            },
            })
            }
            })
        },
    getDetail() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
  const item = JSON.parse(localStorage.getItem('podetail'))
      this.modalDetailShow = true
      this.supplier = `${item.contact.contact_name}  (${item.contact.contact_city})`
      this.shipmentBy = item.purchase_order_shipment_by
      this.paymentType = item.purchase_order_payment_type
      this.deliveryAddress = item.purchase_order_delivery_address
      this.purchaseOrderByPurchasingIsConfirmed = item.purchase_order_is_received_by_purchasing !== null ? true : false
      this.purchaseOrderByWarehouseIsConfirmed = item.purchase_order_is_received_by_warehouse !== null ? true : false
      this.purchaseOrderSchedule = this.dateSimple(item.purchase_order_delivery_schedule)
      this.purchaseOrderDiscountPercentage = item.purchase_order_discount_percentage
      this.purchaseOrderLink = item.purchase_order_link
      this.billingAddress = `${item.branch.branch_code} (${item.branch.branch_city})`
      this.termOfPayment = item.purchase_order_term_of_payment
      this.purchaseOrderWithTax = item.purchase_order_is_with_tax === true ? "Yes" : "No"
      this.type = item.purchase_order_is_center === true ? 'Center' : 'Cabang'
      const id = item.purchase_order_id
      const obj1 = {
        title: 'Created',
        content: `Purchase Order Has been Created By ${item.user.user_fullname} at ${this.dateSimple(item.purchase_order_created_time, 'YYYY-MM-DD HH:mm:ss')}`,
        stepCssClass: "has-step-green",
        boxCssClass: "has-color-red",
      }
      const obj2 = {
        title: 'Laboratory Manager',
        content: item.purchase_order_is_confirmed === true ? `Purchase Order Has been Accepted by ${item.lab_manager.user_fullname} at ${this.dateSimple(item.purchase_order_is_confirmed_date, 'YYYY-MM-DD HH:mm:ss')}` : `Purchase Order Has not been Accepted By Laboratory Manager`,
        stepCssClass: item.purchase_order_is_confirmed === true ? "has-step-green" : "has-step-red",
      }
      const obj3 = {
        title: 'Received Purchasing',
        content: item.purchase_order_is_received_by_purchasing === true ? `Purchase Order Has been Received By Purchasing at ${this.dateSimple(item.purchase_order_is_received_by_purchasing_time, 'YYYY-MM-DD HH:mm:ss')}` : `Purchase Order Has not been Received by Purchasing Cabang`,
        stepCssClass: item.purchase_order_is_received_by_purchasing === true ? "has-step-green" : "has-step-red",
      }
      const obj4 = {
        title: 'Received Warehouse',
        content: item.purchase_order_is_received_by_warehouse === true ? `Purchase Order Has been Received By Warehouse at ${this.dateSimple(item.purchase_order_is_received_by_purchasing_time, 'YYYY-MM-DD HH:mm:ss')}` : `Purchase Order Has not been Received by Warehouse`,
        stepCssClass: item.purchase_order_is_received_by_warehouse === true ? "has-step-green" : "has-step-red",
      }
      this.statusItems = []
      this.statusItems.push(obj1)
      this.statusItems.push(obj2)
      this.statusItems.push(obj3)
      this.statusItems.push(obj4)
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_DETAILS}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          this.items = response.data.data
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Fetching Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
    print() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const item = this.$cookies.get('PurchaseOrderDetail')
      const id = item.purchase_order_id
      axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_PURCHASE_ORDER}${id}`, { headers })
      .then(response => {
        if (response.data.success === true) {
          const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
          window.open(url, '_blank')
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Download Data failed',
                icon: 'CheckIcon',
                variant: 'danger',
              },
            })
        }
        })
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = { label: elem.label, value: elem.value, qty: 1 }
                }
              })

            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
    dateSimple(value, format = 'YYYY-MM-DD') {
        let dateRet = ''
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else { dateRet = null }
        return dateRet
    },
    previous() {
          this.$router.push({ name: 'apps-procurement-purchase-order-list' })
    },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
    },
  }
  </script>